<template>
	<div>
		<h3>{{ msg }}</h3>
		<p>
			主要用CSDN博客记录日常学习工作中遇到的技术知识点，欢迎来
			<a :href="blog" target="_blank" rel="noopener">
				我的博客
			</a>
			“一键三连”.
		</p>
		<h3>个人开源项目</h3>
		<ul>
			<template v-for="site in proj">
			    <li><a :href="site.url" target="_blank" rel="noopener">{{site.name}}</a></li>
			</template>
		</ul>
	</div>
</template>

<script>
	export default {
		name: "HelloWorld",
		data() {
			return {
				msg: "我的CSDN博客",
				blog: "https://blog.csdn.net/weixin_42792301",
				proj: [
					{name:"简单的门户网站及管理端",url:"https://gitee.com/ccwrt/SimplePortalSite"},
					{name:"图片水印",url:"https://gitee.com/ccwrt/image_watermark"},
					{name:"生成数据库设计文档",url:"https://gitee.com/ccwrt/producedatabasedoc"}
				]
			}
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	h3 {
		margin: 2.5rem 0 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #785dff;
		text-decoration: none;
	}
</style>
